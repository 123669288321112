import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import {
  ApiId,
  AppRecord,
  AppRecord$,
  ATTACHMENT_MODEL,
  Dictionary,
  EnqueuedGroup,
  EnqueuedRequest,
  EnqueueOptions,
  IAppRecord,
  IMany2ManyValues,
  IOne2ManyItem,
  Many2Many,
  Many2One,
  OFFLINE_QUEUE_MODEL,
  One2Many,
} from "@app/models";
import { AuthService, ErrorService } from "@app/services";
import { StorageService } from "@app/services/storage.service";
import { Capacitor } from "@capacitor/core";
import { Platform } from "@ionic/angular";
import { isEqual } from "lodash";
import {
  EMPTY,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
  throwError,
} from "rxjs";
import {
  catchError,
  concatMap,
  expand,
  filter,
  last,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  timeout,
  toArray,
} from "rxjs/operators";
import * as uuid from "uuid";
import { validate as uuidValidate } from "uuid";
import { environment } from "../../environments/environment";
import { NetworkService } from "../services/network.service";
import { OdooService } from "./odoo.service";
import { PouchService } from "./pouch.service";

export const ENSURE_FILE_TIMEOUT = 120000;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private http = inject(HttpClient);
  private platform = inject(Platform);
  private authService = inject(AuthService);
  private odooService = inject(OdooService);
  private pouchService = inject(PouchService);
  private errorService = inject(ErrorService);
  private networkService = inject(NetworkService);
  private storageService = inject(StorageService);

  starter = new Subject<EnqueuedGroup>();

  syncEnqueue = new Subject<EnqueuedGroup>();
  syncStarted = new Subject<EnqueuedGroup>();
  syncSuccess = new Subject<EnqueuedGroup>();
  syncFailure = new Subject<EnqueuedGroup>();

  createSuccess = new Subject<{ model: string; data: any }>();
  updateSuccess = new Subject<{ model: string; data: any }>();
  deleteSuccess = new Subject<{ model: string; id: ApiId }>();

  constructor() {}

  public downloadFile(
    model: string,
    fileId: ApiId,
    fileName: string,
    mimeType: string
  ) {
    return this.storageService.readFile(model, fileId).pipe(
      tap((fileData: string) => {
        if (this.platform.is("hybrid")) {
          () => new Error("Download for hybrid app is not implemented");
        } else {
          this.storageService.downloadBase64(fileData, fileName, mimeType);
        }
      })
    );
  }

  public downloadReport(report: string, fileId: ApiId, fileName: string) {
    const report$ = this.isOnline()
      ? this.fetchReport(report, fileId).pipe(
          switchMap((reportData) =>
            this.storageService
              .writeFile(report, fileId, reportData)
              .pipe(map(() => reportData))
          )
        )
      : this.storageService.readFile(report, fileId);

    if (this.platform.is("hybrid")) {
      return throwError(
        () => new Error("Download for hybrid app is not implemented")
      );
    } else {
      return report$.pipe(
        tap((reportData) => {
          this.storageService.downloadBase64(
            reportData,
            fileName,
            "application/pdf"
          );
        }),
        catchError((error) => this.errorService.handleError(error))
      );
    }
  }

  public ensureFile(
    model: string,
    apiId: ApiId,
    mimeType: string
  ): Observable<SafeUrl> {
    return this.storageService.getStats(model, apiId).pipe(
      catchError(() =>
        this.fetchFile(apiId).pipe(
          switchMap(({ datas }) =>
            this.storageService.writeFile(model, apiId, datas)
          )
        )
      ),
      timeout(ENSURE_FILE_TIMEOUT),
      switchMap(() =>
        this.storageService.readFile(model, apiId).pipe(
          switchMap((fileData) => {
            const blob = this.storageService.base64ToBlob(fileData, mimeType);
            return this.storageService.getThumbnail(blob);
          })
        )
      ),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  public ensureReport(report: string, apiId: ApiId) {
    return this.storageService.getStats(report, apiId).pipe(
      catchError(() =>
        this.fetchReport(report, apiId).pipe(
          switchMap((reportData) =>
            this.storageService.writeFile(report, apiId, reportData)
          )
        )
      ),
      timeout(ENSURE_FILE_TIMEOUT),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  public saveFile(
    model: string,
    fileId: ApiId,
    blob: Blob
  ): Observable<SafeUrl> {
    return this.storageService.blobToBase64(blob).pipe(
      switchMap((base64) =>
        this.storageService.writeFile(model, fileId, base64)
      ),
      switchMap(() => this.storageService.getThumbnail(blob))
    );
  }

  public openFile(
    model: string,
    apiId: ApiId,
    mimeType: string
  ): Observable<void> {
    if (this.platform.is("hybrid")) {
      return throwError(() => new Error("Cannot open report in browser"));
    }
    return this.storageService.getUri(model, apiId).pipe(
      map(({ uri }) => Capacitor.convertFileSrc(uri)),
      switchMap((path) => this.storageService.openFile(path, mimeType)),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  public openReport(report: string, apiId: ApiId): Observable<void> {
    if (this.platform.is("hybrid")) {
      return throwError(() => new Error("Cannot open report in browser"));
    }

    const report$: Observable<string> = this.isOnline()
      ? this.fetchReport(report, apiId).pipe(
          switchMap((reportData) =>
            this.storageService.writeFile(report, apiId, reportData)
          ),
          map(({ uri }) => uri)
        )
      : this.storageService.getUri(report, apiId).pipe(map(({ uri }) => uri));

    return report$.pipe(
      map((uri) => Capacitor.convertFileSrc(uri)),
      switchMap((path) =>
        this.storageService.openFile(path, "application/pdf")
      ),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  public deleteFile(model: string, fileId: ApiId) {
    return this.storageService.deleteFile(model, fileId);
  }

  public generateAppId() {
    return uuid.v4();
  }

  public isValidAppId(id: ApiId) {
    return typeof id === "string" && uuidValidate(id);
  }

  private preparePouchId(values: any): ApiId {
    return values._id || values.id || this.generateAppId();
  }

  public preparePouchValues(values: any): Observable<any> {
    const _id = this.preparePouchId(values);
    return of({ ...values, _id });
  }

  private prepareOdooId(model: string, id: ApiId): Observable<ApiId> {
    // TODO get ID from values?
    if (!uuidValidate(id.toString())) {
      return of(id).pipe(map((id) => parseInt(id.toString())));
    } else {
      return this.pouchService.read(model, id).pipe(
        map((pouchData: any) => {
          return pouchData.id || id;
        })
      );
    }
  }

  private prepareOdooRelationId(id: string) {
    return this.pouchService.searchId(id);
  }

  public isOnline(): boolean {
    return this.networkService.isOnline();
  }

  public isOffline(): boolean {
    return this.networkService.isOffline();
  }

  public setOffline(
    model: string,
    onlineData: IAppRecord[]
  ): Observable<IAppRecord[]> {
    return this.pouchService._search(model).pipe(
      switchMap((offlineData) => {
        const oldData = offlineData.map((item) => ({
          _id: item._id,
          _rev: item._rev,
          _deleted: true,
        }));
        return this.pouchService.bulkDocs(oldData).pipe(
          switchMap(() => {
            const newData = onlineData.map((item) => ({
              ...item,
              _id: this.pouchService.slugId(model, this.preparePouchId(item)),
            }));
            return this.pouchService.bulkDocs(newData).pipe(
              map(() =>
                newData.map((item) => ({
                  ...item,
                  _id: this.pouchService.unslugId(item._id),
                }))
              )
            );
          })
        );
      })
    );
  }

  public createOnline(model: string, values: any): Observable<IAppRecord> {
    // do we need to pass a new app record with an _ID?
    const currentOdooValues = new AppRecord();
    return this.prepareOdooValues(model, currentOdooValues, values).pipe(
      switchMap((preparedOdooValues) =>
        this.odooService
          .create(model, preparedOdooValues)
          .pipe(
            switchMap((newOdooValues) =>
              this.pouchService.upsert(model, newOdooValues)
            )
          )
      ),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  public createOffline(
    model: string,
    values: any,
    enqueueOptions: EnqueueOptions
  ): Observable<IAppRecord> {
    return this.preparePouchValues(values).pipe(
      switchMap((offlineValues) =>
        this.pouchService.create(model, offlineValues).pipe(
          switchMap((createdOffline) =>
            this.enqueueOnlineCreate(model, offlineValues, enqueueOptions).pipe(
              tap((enqueuedGroup) => this.syncEnqueue.next(enqueuedGroup)),
              map(() => createdOffline)
            )
          )
        )
      )
    );
  }

  private prepareOdooOne2ManyValues(
    model: string,
    currentValues: IOne2ManyItem[] | undefined,
    newValues: One2Many<IOne2ManyItem>
  ): Observable<any> {
    const currentMap = new Map(currentValues?.map((item) => [item._id, item]));

    const upsertValues$ = newValues.map((newItem) => {
      const currentItem = currentMap.get(newItem._id);
      if (currentItem) {
        newItem.id = currentItem.id;
      }
      currentMap.delete(newItem._id);

      return this.prepareOdooValues(model, null, newItem, false).pipe(
        map((normalizedItem) => {
          if (!currentItem || !isEqual({ ...currentItem }, { ...newItem })) {
            return normalizedItem;
          }
          return null;
        })
      );
    });

    const deleteValues$ = Array.from(currentMap.values()).map((item) =>
      of({ id: item.id })
    );

    return forkJoin([...deleteValues$, ...upsertValues$]).pipe(
      mergeMap((values) => values),
      filter((item) => item !== null),
      toArray(),
      catchError((err) => {
        // TODO: Proper error handling
        return throwError(() => err);
      })
    );
  }

  public prepareOdooValues(
    model: string,
    currentValues: Dictionary,
    newValues: Dictionary,
    isRoot = true
  ): Observable<IAppRecord> {
    const normalizedValues$: AppRecord$ = {};

    for (const field of Object.keys(newValues)) {
      if (field === "id") {
        if (!isRoot) {
          normalizedValues$[field] = of(newValues[field]);
        }
        // else {
        // Do not add id in root values
        // }
        continue;
      }

      if (newValues[field] === null || newValues[field] === undefined) {
        normalizedValues$[field] = of(false);
        continue;
      }

      if (field !== "_id" && this.isValidAppId(newValues[field])) {
        normalizedValues$[field] = this.prepareOdooRelationId(newValues[field]);
        continue;
      }

      if (newValues[field] instanceof Many2One) {
        normalizedValues$[field] = of(newValues[field].id);
        continue;
      }

      if (newValues[field] instanceof One2Many) {
        const one2ManyValues$ = this.prepareOdooOne2ManyValues(
          model,
          currentValues[field],
          newValues[field]
        );
        normalizedValues$[field] = one2ManyValues$;
        continue;
      }

      if (newValues[field] instanceof Many2Many) {
        const many2ManyIds: IMany2ManyValues = [
          ...newValues[field].map(({ id }) => ({ id: id })),
        ];
        normalizedValues$[field] = of(many2ManyIds);
        continue;
      }

      normalizedValues$[field] = of(newValues[field]);
    }

    if (model === ATTACHMENT_MODEL) {
      normalizedValues$["datas"] = this.storageService.readFile(
        model,
        newValues["_id"]
      );
    }

    return forkJoin(normalizedValues$).pipe(
      // TODO error handling?
      catchError((error) => throwError(() => error))
    ) as Observable<IAppRecord>;
  }

  private updateOnline(
    model: string,
    apiId: ApiId,
    apiValues: IAppRecord
  ): Observable<IAppRecord> {
    return this.prepareOdooId(model, apiId).pipe(
      switchMap((odooId) =>
        this.odooService.read(model, odooId).pipe(
          switchMap((currentOdooValues) =>
            this.prepareOdooValues(model, currentOdooValues, apiValues).pipe(
              switchMap((preparedOdooValues) =>
                this.odooService.update(model, odooId, preparedOdooValues)
              ),
              switchMap(() => this.odooService.read(model, odooId)),
              switchMap((newOdooValues) =>
                this.pouchService.upsert(model, newOdooValues)
              )
            )
          )
        )
      ),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  // TODO get rid of normalizePouchValues
  public normalizePouchValues(
    currentValues: IAppRecord,
    newValues: IAppRecord
  ) {
    return newValues;
  }

  private updateOffline(
    model: string,
    id: ApiId,
    values: IAppRecord,
    enqueueOptions: EnqueueOptions
  ): Observable<IAppRecord> {
    return this.pouchService.read(model, id).pipe(
      map((currentValues) => this.normalizePouchValues(currentValues, values)),
      switchMap((newValues) =>
        this.pouchService.update(model, id, newValues).pipe(
          switchMap((updatedOffline) =>
            this.enqueueOnlineUpdate(model, id, values, enqueueOptions).pipe(
              tap((enqueuedGroup) => this.syncEnqueue.next(enqueuedGroup)),
              map(() => updatedOffline)
            )
          )
        )
      )
    );
  }

  private deleteOnline(model: string, apiId: ApiId): Observable<ApiId> {
    return this.prepareOdooId(model, apiId).pipe(
      switchMap((onlineId) => this.odooService.delete(model, onlineId)),
      switchMap(() => this.pouchService.delete(model, apiId)),
      map(() => apiId),
      catchError((error) => this.errorService.handleError(error))
    );
  }

  private deleteOffline(
    model: string,
    apiId: ApiId,
    enqueueOptions: EnqueueOptions
  ): Observable<ApiId> {
    return this.pouchService.delete(model, apiId).pipe(
      switchMap(() => this.enqueueOnlineDelete(model, apiId, enqueueOptions)),
      map(() => apiId)
    );
  }

  public search(model: string): Observable<IAppRecord[]> {
    if (this.isOnline()) {
      return this.odooService.search(model).pipe(
        switchMap((onlineResponse) => this.setOffline(model, onlineResponse)),
        catchError((error) => this.errorService.handleError(error))
      );
    } else {
      return this.pouchService
        .search(model)
        .pipe(catchError((error) => this.errorService.handleError(error)));
    }
  }

  public create(
    model: string,
    values: any,
    enqueueOptions: EnqueueOptions = null
  ): Observable<IAppRecord> {
    if (this.isOnline()) {
      return this.createOnline(model, values);
    } else {
      return this.createOffline(model, values, enqueueOptions);
    }
  }

  public read(model: string, id: ApiId): Observable<IAppRecord> {
    if (this.isOnline()) {
      return this.prepareOdooId(model, id).pipe(
        switchMap((odooId) =>
          this.odooService
            .read(model, odooId)
            .pipe(
              switchMap((odooResponse) =>
                this.pouchService.upsert(model, odooResponse)
              )
            )
        ),
        catchError((error) => this.errorService.handleError(error))
      );
    } else {
      return this.pouchService
        .read(model, id)
        .pipe(catchError((error) => this.errorService.handleError(error)));
    }
  }

  public update(
    model: string,
    apiId: ApiId,
    values: any,
    enqueueOptions: EnqueueOptions = null
  ): Observable<IAppRecord> {
    if (this.isOnline()) {
      return this.updateOnline(model, apiId, values);
    } else {
      return this.updateOffline(model, apiId, values, enqueueOptions);
    }
  }

  public delete(
    model: string,
    apiId: ApiId,
    enqueueOptions: EnqueueOptions = null
  ): Observable<ApiId> {
    if (this.isOnline()) {
      return this.deleteOnline(model, apiId);
    } else {
      return this.deleteOffline(model, apiId, enqueueOptions);
    }
  }

  public fetchFile(apiId: any) {
    return this.http.get<{ datas: string; name: string; id: number }>(
      `${environment.apiUrl}/ir.attachment/${apiId}`,
      {
        params: {
          exclude_fields: JSON.stringify(["*"]),
          include_fields: JSON.stringify(["id", "name", "datas"]),
        },
      }
    );
  }

  public fetchReport(name: string, apiId: ApiId): Observable<string> {
    return this.http
      .get<string>(`${environment.apiUrl}/report/get_pdf`, {
        params: {
          report_name: name,
          ids: [apiId],
        },
      })
      .pipe(map((reportData) => reportData.replace(/(\r\n|\n|\r)/gm, "")));
  }

  public savePrototypes(savedObj: any): any {
    if (Array.isArray(savedObj)) {
      return {
        __prototype: savedObj.constructor.name.replace(/^_/, ""),
        items: savedObj.map((item) => this.savePrototypes(item)),
      };
    } else if (savedObj !== null && typeof savedObj === "object") {
      const plainObj = {
        ...savedObj,
        __prototype: savedObj.constructor.name.replace(/^_/, ""),
      };
      for (const key in plainObj) {
        if (plainObj.hasOwnProperty(key) && typeof plainObj[key] === "object") {
          plainObj[key] = this.savePrototypes(plainObj[key]);
        }
      }
      return plainObj;
    } else {
      return savedObj;
    }
  }

  public restorePrototypes(plainObj: any): any {
    if (plainObj !== null && typeof plainObj === "object") {
      let restoredObj: any;
      // Handle arrays
      if (plainObj.__prototype && plainObj.items) {
        let clonedObjs = Array.isArray(plainObj.items)
          ? Array.from(plainObj.items)
          : plainObj.items;

        switch (plainObj.__prototype) {
          case "One2Many":
            restoredObj = Object.setPrototypeOf(clonedObjs, One2Many.prototype);
            break;
          case "Many2Many":
            restoredObj = Object.setPrototypeOf(
              clonedObjs,
              Many2Many.prototype
            );
            break;
          default:
            restoredObj = clonedObjs;
        }
        restoredObj = restoredObj.map((item: any) =>
          this.restorePrototypes(item)
        );
      } else {
        // Handle object prototypes
        const clonedObj = Object.assign({}, plainObj);
        switch (plainObj.__prototype) {
          case "Many2One":
            restoredObj = Object.setPrototypeOf(clonedObj, Many2One.prototype);
            break;
          case "One2Many":
            restoredObj = Object.setPrototypeOf(clonedObj, One2Many.prototype);
            break;
          case "Many2Many":
            restoredObj = Object.setPrototypeOf(clonedObj, Many2Many.prototype);
            break;
          default:
            restoredObj = clonedObj;
        }
        delete restoredObj.__prototype;

        for (const key in restoredObj) {
          if (
            restoredObj.hasOwnProperty(key) &&
            typeof restoredObj[key] === "object"
          ) {
            const clonedObj = Object.assign({}, restoredObj[key]);
            restoredObj[key] = this.restorePrototypes(clonedObj);
          }
        }
      }
      return restoredObj;
    } else {
      return plainObj; // Primitive types
    }
  }

  public enqueueOnlineRequest(
    method: "create" | "update" | "delete",
    model: string,
    id: ApiId,
    values: IAppRecord,
    options: EnqueueOptions = null
  ): Observable<EnqueuedGroup> {
    options = options ?? { model, id };

    // TODO cope with company/user parameters
    const user = this.authService.getLoggedUser();
    const company_id = this.authService.getCompany();

    const groupId = this.pouchService.slugId(
      `${OFFLINE_QUEUE_MODEL}:${options.model}`,
      options.id
    );
    const groupValues = this.savePrototypes(values);

    return this.pouchService._read(groupId).pipe(
      switchMap((pdbData) => {
        delete pdbData._rev;
        const group = pdbData as unknown as EnqueuedGroup;
        const request: EnqueuedRequest = {
          method,
          model,
          id,
          values: groupValues,
          sequence:
            Math.max(...(group.queue || []).map(({ sequence }) => sequence)) +
            1,
        };
        const updatedGroup: EnqueuedGroup = {
          ...group,
          queue: [...(group.queue || []), request],
        };
        return this.pouchService._update(updatedGroup).pipe(
          map((pdbData) => {
            delete pdbData._rev;
            return pdbData as unknown as EnqueuedGroup;
          })
        );
      }),
      catchError((error) => {
        if (error.status === 404) {
          const request: EnqueuedRequest = {
            method,
            model,
            id,
            values: groupValues,
            sequence: 1,
          };
          const newGroup: EnqueuedGroup = {
            _id: groupId,
            user_id: user.id,
            company_id,
            queue: [request],
          };
          return this.pouchService._create(newGroup).pipe(
            map((pdbData) => {
              delete pdbData._rev;
              return { ...pdbData } as unknown as EnqueuedGroup;
            })
          );
        }
        return throwError(() => new Error("Failed to enqueue request"));
      })
    );
  }

  public enqueueOnlineCreate(
    model: string,
    values: any,
    options: EnqueueOptions = null
  ): Observable<EnqueuedGroup> {
    const id = values._id;
    return this.enqueueOnlineRequest("create", model, id, values, options);
  }

  public enqueueOnlineUpdate(
    model: string,
    id: ApiId,
    values: any,
    options: EnqueueOptions = null
  ): Observable<EnqueuedGroup> {
    return this.enqueueOnlineRequest("update", model, id, values, options);
  }

  public enqueueOnlineDelete(
    model: string,
    id: ApiId,
    options: EnqueueOptions = null
  ): Observable<EnqueuedGroup> {
    return this.enqueueOnlineRequest("delete", model, id, null, options);
  }

  public processEnqueuedRequest(request: EnqueuedRequest): Observable<boolean> {
    let values: IAppRecord;
    try {
      values = this.restorePrototypes({ ...request.values });
    } catch (error) {
      return throwError(
        () => new Error("Failed to restore prototypes: " + error.message)
      );
    }

    switch (request.method) {
      case "create":
        return this.createOnline(request.model, values).pipe(
          tap((onlineResponse) => {
            this.createSuccess.next({
              model: request.model,
              data: onlineResponse,
            });
          }),
          map(() => true)
        );

      case "update":
        return this.updateOnline(request.model, request.id, values).pipe(
          tap((onlineResponse) => {
            this.updateSuccess.next({
              model: request.model,
              data: onlineResponse,
            });
          }),
          map(() => true)
        );

      case "delete":
        return this.deleteOnline(request.model, request.id).pipe(
          tap((apiId) => {
            this.deleteSuccess.next({ model: request.model, id: apiId });
          }),
          map(() => true)
        );

      default:
        return throwError(
          () => new Error(`Unknown request method: ${request.method}`)
        );
    }
  }

  public processEnqueuedGroupSuccess(
    enqueuedGroup: EnqueuedGroup
  ): Observable<boolean> {
    return this.pouchService._delete(enqueuedGroup._id).pipe(
      tap(() => this.syncSuccess.next(enqueuedGroup)),
      map(() => true)
    );
  }

  public processEnqueuedGroupFailure(
    failedGroup: EnqueuedGroup,
    failedQueue: EnqueuedRequest[],
    error: unknown
  ): Observable<never> {
    const remainingGroup: EnqueuedGroup = {
      ...failedGroup,
      queue: failedQueue,
      errors: [...(failedGroup.errors ?? []), error],
    };
    return this.pouchService._update(remainingGroup).pipe(
      tap((updatedGroup: unknown) =>
        this.syncFailure.next(updatedGroup as EnqueuedGroup)
      ),
      switchMap(() => throwError(() => error))
    );
  }

  public processEnqueuedGroup(group: EnqueuedGroup): Observable<boolean> {
    this.syncStarted.next(group);
    return of([...group.queue].sort((a, b) => a.sequence - b.sequence)).pipe(
      expand((queue: EnqueuedRequest[]) => {
        if (queue.length === 0) {
          return EMPTY;
        }
        const request: EnqueuedRequest = queue[0];
        return this.processEnqueuedRequest(request).pipe(
          map(() => {
            queue.shift();
            return queue;
          }),
          catchError((error) =>
            this.processEnqueuedGroupFailure(group, queue, error)
          )
        );
      }),
      take(group.queue.length + 1),
      last(),
      switchMap(() => this.processEnqueuedGroupSuccess(group)),
      catchError(() => of(false))
    );
  }

  public searchOfflineQueue(): Observable<EnqueuedGroup[]> {
    const user = this.authService.getLoggedUser();
    const company_id = this.authService.getCompany();
    return this.pouchService._search(OFFLINE_QUEUE_MODEL).pipe(
      map((queue) =>
        queue
          .map((group) => {
            delete group._rev;
            return group as unknown as EnqueuedGroup;
          })
          .filter(
            (group) =>
              group.user_id === user.id && group.company_id === company_id
          )
      )
    );
  }

  public processOfflineQueue(): Observable<boolean> {
    return this.searchOfflineQueue().pipe(
      switchMap((offlineQueue) => from(offlineQueue)),
      concatMap((enqueuedGroup) => this.processEnqueuedGroup(enqueuedGroup))
    );
  }
}
